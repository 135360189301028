//zoom layout
#zmmtg-root {
  .open.btn-group {
    //.popmenu, .more-button__pop-menu, .dropdown-menu {
    //  display: block;
    //}
    ul[role=menu] {
      display: block;
    }
  }
  li[role=heading] {
    background-color: black;
  }

}
.none-white-space .rt-th,
.none-white-space .rt-td {
  white-space: unset!important;
}
body {
  padding: 0!important;
}
.font-14 {
  font-size: 14px!important;
  color: #2C4775;
}
.modal-title {
  color: #2C4775!important;
}
.align-center {
  text-align: center!important;
}
.rt-resizable-header {
  text-align: left;
}
.white-bg {
  background-color: white!important;
}
